<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      usersData: [],
      form: {}
    }
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    loadUser() {
      try {
        service.serviceGetUsers().then((response) => {
          switch (response.status) {
            case 200:
              this.usersData = response.results
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createdUser() {
      try {
        // console.log(this.form)
        if (this.form.password !== this.form.repeatPassword) {
          console.log('password tidak sama')
        } else {
          service.servicePostUser(this.form).then((response) => {
            console.log(response)
          })
        }
      } catch (error) {
        console.log(this.error)
      }
    },

    updateUser() {},

    deleteUser() {
      // console.log(this.form.idPerson)
      try {
        service.serviceDeleteUser(this.form.idPerson).then((response) => {
          switch (response.status) {
            case 200:
              console.log(response.message)
              break
            default:
              console.log(response.message)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
