<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Category Layout</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="
          intro-y
          col-span-12
          flex flex-wrap
          sm:flex-nowrap
          items-center
          mt-2
        "
      >
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#added-category-modal"
        >
          <button class="btn btn-primary shadow-md mr-2">
            Add New Category
          </button></a
        >

        <div class="dropdown">
          <button
            class="
              dropdown-toggle
              btn
              px-2
              box
              text-gray-700
              dark:text-gray-300
            "
            aria-expanded="false"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Add Group
              </a>
              <a
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
              >
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Message
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Category -->
      <div
        v-for="(cat, index) in categoryData"
        :key="index"
        class="intro-y col-span-12 md:col-span-6"
      >
        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div class="report-box zoom-in">
            <div class="box p-5">
              <div class="flex">
                <!-- <ShoppingCartIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-3xl font-medium leading-8">
                  {{ cat.category }}
                </div>
                <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
                  <a
                    class="dropdown-toggle w-5 h-5 block"
                    href="javascript:;"
                    aria-expanded="false"
                  >
                    <MoreVerticalIcon
                      class="w-5 h-5 text-gray-600 dark:text-gray-300"
                    />
                  </a>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        class="
                          flex
                          items-center
                          block
                          p-2
                          transition
                          duration-300
                          ease-in-out
                          bg-white
                          dark:bg-dark-1
                          hover:bg-gray-200
                          dark:hover:bg-dark-2
                          rounded-md
                        "
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#update-category-modal"
                      >
                        <Edit2Icon class="w-4 h-4 mr-2" /> Edit
                      </a>
                      <a
                        class="
                          flex
                          items-center
                          block
                          p-2
                          transition
                          duration-300
                          ease-in-out
                          bg-white
                          dark:bg-dark-1
                          hover:bg-gray-200
                          dark:hover:bg-dark-2
                          rounded-md
                        "
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#delete-confirmation-modal"
                        v-on:click="form = cat"
                      >
                        <TrashIcon class="w-4 h-4 mr-2" />
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="text-3xl font-medium leading-8 mt-6">
                {{ cat.category }}
              </div>
              <div class="text-base text-gray-600 mt-1">Item Sales</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- END: Category -->
      <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
        <a
          class="dropdown-toggle w-5 h-5 block"
          href="javascript:;"
          aria-expanded="false"
        >
          <MoreHorizontalIcon
            class="w-5 h-5 text-gray-600 dark:text-gray-300"
          />
        </a>
        <div class="dropdown-menu w-40">
          <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
            <a
              href=""
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
                rounded-md
              "
            >
              <Edit2Icon class="w-4 h-4 mr-2" /> Edit
            </a>
            <a
              href=""
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
                rounded-md
              "
            >
              <TrashIcon class="w-4 h-4 mr-2" /> Delete
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: added Confirmation Modal -->
    <div
      id="added-category-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-left">
              <div class="text-3xl">Add Category</div>
              <div class="mt-6">
                <label for="crud-form-1" class="form-label">Category</label>
                <input
                  id="crud-form-1"
                  type="text"
                  class="form-control w-full"
                  placeholder="Input Category"
                  v-model="form"
                />
              </div>
            </div>
            <div class="px-5 pb-8 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button
                @click="createCategory()"
                type="button"
                class="btn btn-primary w-24"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: added Confirmation Modal -->
    <div
      id="update-category-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-left">
              <div class="text-3xl">Update Category</div>
              <div class="mt-6">
                <label for="crud-form-1" class="form-label">Category</label>
                <input
                  id="crud-form-1"
                  type="text"
                  class="form-control w-full"
                  placeholder="Input Category"
                  v-model="form"
                />
              </div>
            </div>
            <div class="px-5 pb-8 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button
                @click="createCategory()"
                type="button"
                class="btn btn-primary w-24"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="deleteCategory()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>
<script src="./Script.vue" />
