import axios from 'axios'

const apiURL = 'https://kalenderkonten.com/app-dashboard'

export default class ApiService {
  // Begin method user
  async serviceGetUsers() {
    const result = await axios.get(apiURL + '/person')
    return result.data
  }

  async servicePostUser(form) {
    console.log(form)
    // const result = await axios.post(baseURL + '/person', {

    // })
    // return result.data
  }

  async serviceUpdateUser() {
    const result = await axios.put(apiURL + '/person/update/')
    return result.data
  }

  async serviceDeleteUser(id) {
    const result = await axios.delete(apiURL + '/person/delete/' + id)
    return result.data
  }
  // End method user

  // ====================================================================================================

  // Begin method team
  async serviceGetTeam() {
    const result = await axios.get(apiURL + '/team')
    return result.data
  }

  async servicePostTeam() {
    const result = await axios.post(apiURL + '/team')
    return result.data
  }

  async serviceUpdateTeam() {
    const result = await axios.put(apiURL + '/team/')
    return result.data
  }

  async serviceDeleteTeam() {
    const result = await axios.delete(apiURL + '/team/delete/')
    return result.data
  }

  // End method team

  // ====================================================================================================

  // Begin  category
  async serviceGetCategory() {
    const result = await axios.get(apiURL + '/category')
    return result.data
  }

  async servicePostCategory(form) {
    const result = await axios.post(apiURL + '/category', {
      category: form
    })
    return result.data
  }

  async serviceUpdateCategory() {
    const result = await axios.put(apiURL + '/category/update/')
    return result.data
  }

  async serviceDeleteCategory(id) {
    const result = await axios.put(apiURL + '/category/delete/' + id)
    return result.data
  }

  // End method category

  // ====================================================================================================

  //  Begin method login

  async serviceLogin(form) {
    // console.log('service class')
    // console.log(form.email)
    // console.log(form.password)
    const results = await axios.post(apiURL + '/admin/login', {
      username: form.username,
      password: form.password
    })
    return results.data
  }

  //  End method login

  // ====================================================================================================

  //  Begin method customer register product

  async serviceGetOrder() {
    const result = await axios.get(apiURL + '/registerpaket')
    return result.data
  }

  async serviceRegisterProduct() {
    const result = await axios.post(apiURL + '/registerpaket')
    return result.data
  }
  //  End method customer register product

  // ====================================================================================================

  //  Begin method Opsi Bayar

  async serviceOpsi() {
    const result = await axios.get(apiURL + '/opsibayar')
    return result.data
  }

  //  End method Opsi Bayar

  // ====================================================================================================

  //  Begin method Voucher

  async serviceVoucher() {
    const result = await axios.get(apiURL + '/kupon')
    return result.data
  }

  //  End method Voucher

  // ====================================================================================================

  //  Begin method Paket

  async servicePaket() {
    const result = await axios.get(apiURL + '/paket')
    return result.data
  }

  async servicePostPaket(form) {
    const result = await axios.post(apiURL + '/paket', form)
    return result.data
  }

  async servicePutPaket(form) { }

  async serviceDeletePaket(form) { }

  //  End method Paket

  // ====================================================================================================

  //  Begin method Email
  async serviceGetEmail() { }

  async servicePostEmail() { }

  async servicePutEmail() { }

  async serviceDeleteEmail() { }
  //  End method Email

  /**
   * begin event
   */
  async serviceGetEvent() {
    const result = await axios.get(apiURL + '/kegiatan')
    return result.data
  }

  async serviceApproveEvent(id, status) {
    const result = await axios.put(apiURL + '/kegiatan/' + id, { status: status })
    return result.data
  }

  async serviceDeleteEvent(id) {
    const result = await axios.delete(apiURL + '/kegiatan/delete/' + id)
    return result.data
  }

  async serviceSaveEvent(url, form) {
    const result = await axios.post(apiURL + url, form)
    return result.data
  }

  /**
   * end event
   */
  /**
   * chat
   */
  serviceGet(id) {
    return axios.get(apiURL + '/diskusi/show/' + id).then((result) => {
      return result.data
    })
  }

  ambilpesan(id) {
    return axios.get(apiURL + '/diskusi/ambil/' + id).then((result) => {
      return result.data
    })
  }

  saveMessage(message, id) {
    return axios.put(apiURL + '/diskusi/update/' + id, message).then((result) => {
      return result.data
    })
  }
}
