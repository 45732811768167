import Toastify from 'toastify-js'

export default class Toast {
  gagal(text) {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      text: text,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }

  berhasil(text) {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      text: text,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}
