
<script>
import { defineComponent, reactive, toRefs } from 'vue'
import Calendar from '@/components/calendar/Main.vue'
import {
  required,
  minLength,
  maxLength
  // url
  // email,
  // integer
} from '@vuelidate/validators'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { useVuelidate } from '@vuelidate/core'
import imageCompressor from 'vue-image-compressor'
import { nanoid } from 'nanoid'
import ApiService from '../../service/api'
import Chat from '../../components/Chat/Chat.vue'
import Toast from '../../utils/toster'
const service = new ApiService()
const peringatan = new Toast()
export default defineComponent({
  components: {
    Calendar,
    imageCompressor,
    chats: Chat
  },
  data() {
    return {
      category: [],
      tag: '',
      ListTags: [],
      scale: 60,
      quality: 90,
      listGambar: [],
      listHapus: [],
      editKegiatan: false,
      selectedEvent: {
        id: '',
        kegiatan: {},
        listGambar: [],
        listHapus: [],
        ListTags: []
      },
      selectedEventColor: '',
      loading: false,
      calendarKey: '',
      posts: [],
      auth: {},
      warna: {
        approve: '#dc3545',
        progress: '#ffc107',
        done: '#007bff',
        draft: '#2ECC71 '
      },
      listChats: [],
      loadingChat: false,
      // calendarOptions: {
      //   plugins: [dayGridPlugin, interactionPlugin],
      //   initialView: 'dayGridMonth',
      // dateClick: this.handleDateClick,
      // eventClick: this.eventShow,
      //   selectable: true,
      //   events: [
      //     { title: 'event 1', date: '2022-02-01' },
      //     { title: 'event 2', date: '2022-02-02' },
      //     { title: 'event 3', date: '2022-02-02' }
      //   ]
      // },
      calendarOptions: {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        droppable: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        initialView: 'dayGridMonth',
        navLinks: true,
        editable: true,
        dayMaxEvents: true,
        dateClick: this.handleDateClick,
        eventColor: 'green',
        eventClick: this.eventShow,
        events: []
        // events: [
        //   {
        //     title: 'All Day Event',
        //     start: '2022-02-01'
        //   },
        //   {
        //     title: 'Long Event',
        //     start: '2022-02-10',
        //     end: '2022-02-10',
        //     color: '#ffc107' // override!
        //   },
        //   {
        //     groupId: '999',
        //     title: 'Repeating Event',
        //     start: '2022-02-10T16:00:00'
        //   },
        //   {
        //     groupId: '999',
        //     title: 'Repeating Event',
        //     start: '2022-02-16T16:00:00'
        //   },
        //   {
        //     title: 'Conference',
        //     start: '2022-02-10',
        //     end: '2022-02-10',
        //     color: 'RED' // override!
        //   },
        //   {
        //     title: 'Lunch',
        //     start: '2022-02-12T12:00:00'
        //   },
        //   {
        //     title: 'Meeting',
        //     start: '2022-02-12T14:30:00'
        //   },
        //   {
        //     title: 'Birthday Party',
        //     start: '2022-02-13T07:00:00'
        //   },
        //   {
        //     title: 'Click for Google',
        //     url: 'http://google.com/',
        //     start: '2022-02-28'
        //   }
        // ]
        // events: [
        //   { title: 'event 1', date: '2022-02-01' },
        //   { title: 'event 2', date: '2022-02-02 12:00' },
        //   { title: 'event 3', date: '2022-02-13 12:30' }
        // ]
      }
    }
  },
  mounted() {
    // this.kegiatan.listHapus = [];
    this.loadEvent()
    this.loadCategory()
    this.auth = JSON.parse(localStorage.getItem('AUTH'))
  },
  setup() {
    const initialState = {
      title: '',
      subtitle: '',
      tanggal: '',
      jamPosting: '',
      kategori: '',
      caption: '',
      videoUrl: '',
      listGambar: [],
      listHapus: [],
      ListTags: [],
      status: ''
    }
    const formData = reactive({ ...initialState })
    const rules = {
      title: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(255)
      },
      subtitle: {
        minLength: minLength(6),
        maxLength: maxLength(255)
      },
      caption: {},
      videoUrl: {},
      tanggal: {
        required
      },
      jamPosting: {
        required
      },
      kategori: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const save = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        peringatan.gagal('Periksa kembali input field')
      }
    }
    const dragableOptions = {
      itemSelector: '.event',
      eventData(eventEl) {
        return {
          title: cash(eventEl).find('.event__title').html(),
          duration: {
            days: parseInt(cash(eventEl).find('.event__days').text())
          }
        }
      }
    }
    // formData.caption = ref('<p>Tulis caption disini</p>')
    return {
      validate,
      formData,
      dragableOptions,
      save,
      initialState
    }
  },
  methods: {
    showChat(selected) {
      this.loadingChat = true
      this.listChats = []
      service.serviceGet(selected.id).then((res) => {
        switch (res.status) {
          case 404:
            this.loadingChat = false
            peringatan.gagal('chat gagal di load')
            break
          default:
            this.listChats =
              res.results.content.length > 0 ? JSON.parse(res.results.content) : []
            this.loadingChat = false
            cash('#chatc-modal').modal('show')
        }
      })
    },
    closeChat: function () {
      cash('#chatc-modal').modal('hide')
      this.listChats = []
      this.loadEvent()
    },
    getFiles(obj) {
      console.log(obj)
    },
    eventShow: function (arg) {
      // cash('#chatc-modal').modal('show')
      this.selectedEvent = arg.event._def.extendedProps.kegiatan
      this.selectedEventColor = arg.event._def.ui.backgroundColor
      cash('#side-preview').modal('show')
    },
    eventUpdate: function () {
      const data = {
        title: this.selectedEvent.title,
        subtitle: this.selectedEvent.subtitle,
        tanggal: this.selectedEvent.tanggal,
        jamPosting: this.selectedEvent.jam,
        kategori: this.selectedEvent.kategori,
        caption: this.selectedEvent.deskripsi,
        videoUrl: this.selectedEvent.video,
        status: this.selectedEvent.status
      }
      this.listGambar = this.selectedEvent.listGambar
      this.listHapus = this.selectedEvent.listHapus
      this.ListTags = this.selectedEvent.ListTags
      Object.assign(this.formData, data)
      cash('#dialog-kegiatan').modal('show')
      this.editKegiatan = true
    },
    deleteDialog () {
      cash('#delete-modal').modal('show')
    },
    eventDelete: function () {
      this.loading = true
      service
        .serviceDeleteEvent(this.selectedEvent.idKegiatan, status)
        .then((result) => {
          this.loading = false
          switch (result.status) {
            case 200:
              this.loadEvent()
              this.editKegiatan = false
              cash('#delete-modal').modal('hide')
              peringatan.berhasil('Post berhasil di hapus')
              break
            case 400:
              peringatan.gagal('Post gagal di hapus')
              break
            default:
              peringatan.gagal('Post gagal di simpan')
              break
          }
        })
        .catch(() => {
          this.loading = false
          peringatan.gagal('Terjadi kesalahan dalam proses ')
        })
    },
    handleDateClick: function (arg) {
      this.formData.tanggal = arg.dateStr
      // alert('Resource ID: ' + arg.resource.id)
      cash('#dialog-kegiatan').modal('show')
    },
    async handleFileUpload(e) {
      e.target.files.forEach((item, key) => {
        this.createBase64Image(item, key)
      }) // get first file
    },
    createBase64Image(fileObject, key) {
      const reader = new FileReader()
      const ext = fileObject.type.split('/')
      reader.readAsDataURL(fileObject)
      return (reader.onload = (e) => {
        this.listGambar.push({
          stats: 'baru',
          ext: ext[1],
          img: e.target.result,
          id: key
        })
      })
    },
    openfile() {
      $('#imgupload').trigger('click')
    },
    removeTags(item) {
      this.ListTags.splice(this.ListTags.indexOf(item), 1)
      this.ListTags = [...this.ListTags]
    },
    removeImage(item) {
      this.listHapus.push({
        ext: '',
        img: item.imgRaw,
        idImage: item.idImage
      })
      this.listGambar.splice(this.listGambar.indexOf(item), 1)
      this.listGambar = [...this.listGambar]
    },
    loadCategory() {
      try {
        service
          .serviceGetCategory()
          .then((result) => {
            switch (result.status) {
              case 200:
                result.results.forEach((data) => {
                  this.category.push({
                    idCategory: data.idCategory,
                    category: data.category
                  })
                })
                break
              case 404:
                // this.snackbar = true;
                // this.snackbarText = result.data.message;
                break
              default:
                // this.snackbar = true;
                // this.snackbarText = result.data.message;
                break
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    addTag() {
      this.ListTags.push(this.tag)
      this.tag = ''
    },
    checkKey: function (e) {
      if (e.keyCode === 188) {
        const isi = this.tag.replace(',', '')
        this.ListTags.push(isi)
        this.tag = ''
      }
    },
    loadEvent() {
      service
        .serviceGetEvent()
        .then((result) => {
          const posts = []
          result.results.forEach((data) => {
            posts.push({
              id: data.idKegiatan,
              title: data.jam + ' ' + data.namaKategori,
              start: data.tanggal,
              kegiatan: data,
              color:
                data.status === 'Approve'
                  ? this.warna.approve
                  : data.status === 'Progress'
                    ? this.warna.progress
                    : data.status === 'Draft'
                      ? this.warna.draft
                      : this.warna.done
            })
          })
          this.calendarOptions.events = posts
          this.calendarKey = nanoid(20)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    approve(status) {
      this.loading = true
      service
        .serviceApproveEvent(this.selectedEvent.idKegiatan, status)
        .then((result) => {
          this.loading = false
          switch (result.status) {
            case 200:
              this.loadEvent()
              this.editKegiatan = false
              cash('#dialog-kegiatan').modal('hide')
              Object.assign(this.formData, this.initialState)
              peringatan.berhasil('Data berhasil di simpan')
              break
            case 400:
              peringatan.gagal('Data gagal di simpan')
              break
            default:
              peringatan.gagal('Data gagal di simpan')
              break
          }
        })
        .catch(() => {
          this.loading = false
          peringatan.gagal('Terjadi kesalahan dalam menyimpan ')
        })
    },
    simpanKegiatan() {
      let url = ''
      this.save()
      this.formData.tanggal =
        this.formData.tanggal + ' ' + this.formData.jamPosting
      this.formData.listGambar = this.listGambar
      this.formData.status = this.auth.level == 'admin' ? 'Approve' : 'Draft'
      if (this.formData.videoUrl == undefined) {
        this.videoUrl.videoUrl = ''
      }
      if (this.formData.caption == undefined) {
        this.formData.caption = ''
      }
      if (this.formData.ListTags.length == 0) {
        this.formData.ListTags = this.ListTags
      }
      if (this.formData.listGambar.length == 0) {
        this.formData.listGambar = this.listGambar
      }
      if (!this.editKegiatan) {
        url = '/kegiatan'
      } else {
        url = '/kegiatan/update/' + this.selectedEvent.idKegiatan
      }
      this.loading = true
      service
        .serviceSaveEvent(url, this.formData)
        .then((result) => {
          this.loading = false
          switch (result.status) {
            case 200:
              cash('#dialog-kegiatan').modal('hide')
              this.loadEvent()
              this.editKegiatan = false
              Object.assign(this.formData, this.initialState)
              peringatan.berhasil('Posting berhasil di simpan')
              break
            case 400:
              peringatan.gagal('Posting gagal di simpan')
              break
            default:
              peringatan.gagal('Posting gagal di simpan')
              break
          }
        })
        .catch(() => {
          this.loading = false
          peringatan.gagal('Terjadi kesalahan pada proses simpan')
        })
    }
  }
})
</script>
