<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="mr-auto text-lg font-medium">Email Layout</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Classic Editor -->
      <div class="col-span-12">
        <div class="box">
          <div
            class="flex flex-col items-center p-5 border-b border-gray-200  sm:flex-row dark:border-dark-5"
          >
            <h2 class="mr-auto text-base font-medium">Email Layout</h2>
            <!-- <div
              class="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0"
            >
              <label class="ml-0 form-check-label sm:ml-2" for="show-example-2"
                >Show example code</label
              >
              <input
                id="show-example-2"
                data-target="#classic-editor"
                class="ml-3 mr-0 show-code form-check-switch"
                type="checkbox"
              />
            </div> -->
          </div>
          <div id="classic-editor" class="p-5">
            <div class="preview">
              <ClassicEditor v-model="editorData" />
            </div>
            <div class="hidden source-code">
              <button
                data-target="#copy-classic-editor"
                class="px-2 py-1 copy-code btn btn-outline-secondary"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="mt-3 overflow-y-auto rounded-md">
                <Highlight id="copy-classic-editor" class="source-preview">
                  <code
                    class="p-0 pt-8 pb-4 pl-5 -mt-10 -mb-10 text-xs rounded-md  html"
                  >
                    <textarea>
                      <ClassicEditor v-model="editorData" />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col items-center p-5 border-b border-gray-200  sm:flex-row dark:border-dark-5"
          >
            <h2 class="mr-auto text-base font-medium"></h2>

            <button
              @click="createEmail()"
              type="button"
              class="w-24 btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <!-- END: Classic Editor -->
    </div>
  </div>
</template>
<script src="./Script.vue" />
