<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      categoryData: [],
      form: null
    }
  },
  mounted() {
    this.loadCategory()
  },
  methods: {
    loadCategory() {
      try {
        service.serviceGetCategory().then((response) => {
          switch (response.status) {
            case 200:
              this.categoryData = response.results
              console.table(this.categoryData)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createCategory() {
      try {
        service.servicePostCategory(this.form).then((response) => {
          console.log(response)
        })
      } catch (error) {
        console.log(error)
      }
    },

    updateCategory() {
      try {
        console.log(this.form)
      } catch (error) {
        console.log(error)
      }
    },

    deleteCategory() {
      try {
        service.serviceDeleteCategory(this.form.idCategory).then((response) => {
          console.log(response)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
