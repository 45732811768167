<script>
import { defineComponent, ref } from 'vue'
import ApiService from '../../service/api'
const service = new ApiService()

export default defineComponent({
  setup() {
    const date = ref('')
    const editorData = ref('<p>Content of the editor.</p>')

    return {
      date,
      editorData
    }
  },
  methods: {
    createEmail() {
      try {
        service.servicePostEmail().then((response) => {
          console.log(response)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>
