<template>
  <div>
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <h2 class="mr-auto text-lg font-medium">Calendar</h2>
      <div class="flex w-full mt-4 sm:w-auto sm:mt-0">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#dialog-kegiatan"
        >
          <button class="mr-2 shadow-md btn btn-primary">
            <PlusIcon class="w-4 h-4 mr-2" /> Post
          </button></a
        >
        <!-- <div class="ml-auto dropdown sm:ml-0">
          <button
            class="
              px-2
              text-gray-700
              dropdown-toggle
              btn
              box
              dark:text-gray-300
            "
            aria-expanded="false"
          >
            <span class="flex items-center justify-center w-5 h-5">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  rounded-md
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                "
              >
                <Share2Icon class="w-4 h-4 mr-2" /> Share
              </a>
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  rounded-md
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                "
              >
                <SettingsIcon class="w-4 h-4 mr-2" /> Settings
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <!-- BEGIN: Calendar Side Menu -->
      <!-- <div class="col-span-12 xl:col-span-4 2xl:col-span-3">
        <div class="p-5 box intro-y">
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#header-footer-modal-preview"
            class="w-full mt-2 btn btn-primary"
          >
            <PlusIcon class="w-4 h-4 mr-2" /> Kegiatan
          </a>
           <FullCalendarDraggable
            id="calendar-events"
            :options="dragableOptions"
            class="
              py-3
              mt-6
              mb-5
              border-t border-b border-gray-200
              dark:border-dark-5
            "
          >
            <div class="relative">
              <div
                class="
                  flex
                  items-center
                  p-3
                  -mx-3
                  transition
                  duration-300
                  ease-in-out
                  rounded-md
                  cursor-pointer
                  event
                  hover:bg-gray-200
                  dark:hover:bg-dark-1
                "
              >
                <div class="w-2 h-2 mr-3 rounded-full bg-theme-11"></div>
                <div class="pr-10">
                  <div class="truncate event__title">VueJS Amsterdam</div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    <span class="event__days">2</span> Days
                    <span class="mx-1">•</span> 10:00 AM
                  </div>
                </div>
              </div>
              <a
                class="
                  absolute
                  top-0
                  bottom-0
                  right-0
                  flex
                  items-center
                  my-auto
                "
                href=""
              >
                <EditIcon class="w-4 h-4 text-gray-600" />
              </a>
            </div>
            <div class="relative">
              <div
                class="
                  flex
                  items-center
                  p-3
                  -mx-3
                  transition
                  duration-300
                  ease-in-out
                  rounded-md
                  cursor-pointer
                  event
                  hover:bg-gray-200
                  dark:hover:bg-dark-1
                "
              >
                <div class="w-2 h-2 mr-3 rounded-full bg-theme-12"></div>
                <div class="pr-10">
                  <div class="truncate event__title">Vue Fes Japan 2019</div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    <span class="event__days">3</span> Days
                    <span class="mx-1">•</span> 07:00 AM
                  </div>
                </div>
              </div>
              <a
                class="
                  absolute
                  top-0
                  bottom-0
                  right-0
                  flex
                  items-center
                  my-auto
                "
                href=""
              >
                <EditIcon class="w-4 h-4 text-gray-600" />
              </a>
            </div>
            <div class="relative">
              <div
                class="
                  flex
                  items-center
                  p-3
                  -mx-3
                  transition
                  duration-300
                  ease-in-out
                  rounded-md
                  cursor-pointer
                  event
                  hover:bg-gray-200
                  dark:hover:bg-dark-1
                "
              >
                <div class="w-2 h-2 mr-3 rounded-full bg-theme-11"></div>
                <div class="pr-10">
                  <div class="truncate event__title">Laracon 2021</div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    <span class="event__days">4</span> Days
                    <span class="mx-1">•</span> 11:00 AM
                  </div>
                </div>
              </div>
              <a
                class="
                  absolute
                  top-0
                  bottom-0
                  right-0
                  flex
                  items-center
                  my-auto
                "
                href=""
              >
                <EditIcon class="w-4 h-4 text-gray-600" />
              </a>
            </div>
            <div
              id="calendar-no-events"
              class="hidden p-3 text-center text-gray-600"
            >
              No events yet
            </div>
          </FullCalendarDraggable> -->
      <!-- <div class="form-check">
            <label class="form-check-label" for="checkbox-events"
              >Remove after drop</label
            >
            <input
              id="checkbox-events"
              class="ml-auto show-code form-check-switch"
              type="checkbox"
            />
        </div>
        </div>
        <div class="p-5 mt-5 box intro-y">
          <div class="flex">
            <ChevronLeftIcon class="w-5 h-5 text-gray-600" />
            <div class="mx-auto text-base font-medium">April</div>
            <ChevronRightIcon class="w-5 h-5 text-gray-600" />
          </div>
          <div class="grid grid-cols-7 gap-4 mt-5 text-center">
            <div class="font-medium">Su</div>
            <div class="font-medium">Mo</div>
            <div class="font-medium">Tu</div>
            <div class="font-medium">We</div>
            <div class="font-medium">Th</div>
            <div class="font-medium">Fr</div>
            <div class="font-medium">Sa</div>
            <div class="py-0.5 rounded relative text-gray-600">29</div>
            <div class="py-0.5 rounded relative text-gray-600">30</div>
            <div class="py-0.5 rounded relative text-gray-600">31</div>
            <div class="py-0.5 rounded relative">1</div>
            <div class="py-0.5 rounded relative">2</div>
            <div class="py-0.5 rounded relative">3</div>
            <div class="py-0.5 rounded relative">4</div>
            <div class="py-0.5 rounded relative">5</div>
            <div class="py-0.5 bg-theme-18 dark:bg-theme-9 rounded relative">
              6
            </div>
            <div class="py-0.5 rounded relative">7</div>
            <div
              class="
                py-0.5
                bg-theme-1
                dark:bg-theme-1
                text-white
                rounded
                relative
              "
            >
              8
            </div>
            <div class="py-0.5 rounded relative">9</div>
            <div class="py-0.5 rounded relative">10</div>
            <div class="py-0.5 rounded relative">11</div>
            <div class="py-0.5 rounded relative">12</div>
            <div class="py-0.5 rounded relative">13</div>
            <div class="py-0.5 rounded relative">14</div>
            <div class="py-0.5 rounded relative">15</div>
            <div class="py-0.5 rounded relative">16</div>
            <div class="py-0.5 rounded relative">17</div>
            <div class="py-0.5 rounded relative">18</div>
            <div class="py-0.5 rounded relative">19</div>
            <div class="py-0.5 rounded relative">20</div>
            <div class="py-0.5 rounded relative">21</div>
            <div class="py-0.5 rounded relative">22</div>
            <div class="py-0.5 bg-theme-17 dark:bg-theme-11 rounded relative">
              23
            </div>
            <div class="py-0.5 rounded relative">24</div>
            <div class="py-0.5 rounded relative">25</div>
            <div class="py-0.5 rounded relative">26</div>
            <div class="py-0.5 bg-theme-14 dark:bg-theme-12 rounded relative">
              27
            </div>
            <div class="py-0.5 rounded relative">28</div>
            <div class="py-0.5 rounded relative">29</div>
            <div class="py-0.5 rounded relative">30</div>
            <div class="py-0.5 rounded relative text-gray-600">1</div>
            <div class="py-0.5 rounded relative text-gray-600">2</div>
            <div class="py-0.5 rounded relative text-gray-600">3</div>
            <div class="py-0.5 rounded relative text-gray-600">4</div>
            <div class="py-0.5 rounded relative text-gray-600">5</div>
            <div class="py-0.5 rounded relative text-gray-600">6</div>
            <div class="py-0.5 rounded relative text-gray-600">7</div>
            <div class="py-0.5 rounded relative text-gray-600">8</div>
            <div class="py-0.5 rounded relative text-gray-600">9</div>
          </div>
          <div class="pt-5 mt-5 border-t border-gray-200 dark:border-dark-5">
            <div class="flex items-center">
              <div class="w-2 h-2 mr-3 rounded-full bg-theme-11"></div>
              <span class="truncate">Independence Day</span>
              <div
                class="
                  flex-1
                  h-px
                  mx-3
                  border border-r border-gray-300 border-dashed
                  xl:hidden
                "
              ></div>
              <span class="font-medium xl:ml-auto">23th</span>
            </div>
            <div class="flex items-center mt-4">
              <div
                class="w-2 h-2 mr-3 rounded-full bg-theme-1 dark:bg-theme-10"
              ></div>
              <span class="truncate">Memorial Day</span>
              <div
                class="
                  flex-1
                  h-px
                  mx-3
                  border border-r border-gray-300 border-dashed
                  xl:hidden
                "
              ></div>
              <span class="font-medium xl:ml-auto">10th</span>
            </div>
          </div>
        </div>
      </div>-->
      <!-- END: Calendar Side Menu -->
      <!-- BEGIN: Calendar Content -->
      <div class="col-span-12 xl:col-span-12 2xl:col-span-12">
        <div class="p-1 box">
          <Calendar :options="calendarOptions" :key="calendarKey" />
        </div>
      </div>
      <!-- END: Calendar Content -->
    </div>
  </div>
  <div
    id="dialog-kegiatan"
    data-backdrop="static"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <form class="validate-form" @submit.prevent="simpanKegiatan">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="mr-auto text-base font-medium">Jadwal Posting Baru</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body">
            <div class="input-form grid grid-cols-12 gap-4 gap-y-3">
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-1" class="form-label"
                  >Tanggal Posting</label
                >
                <Litepicker
                  id="validation-form-1"
                  v-model.trim="validate.tanggal.$model"
                  name="tanggal"
                  :class="{ 'border-theme-6': validate.tanggal.$error }"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    format: 'YYYY-MM-DD',
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true
                    }
                  }"
                  class="form-control w-full block mx-auto"
                />
                <template v-if="validate.tanggal.$error">
                  <div
                    v-for="(error, index) in validate.tanggal.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-2" class="form-label">Jam Posting</label>
                <input
                  id="validation-form-2"
                  v-model.trim="validate.jamPosting.$model"
                  type="text"
                  class="form-control"
                  :class="{ 'border-theme-6': validate.jamPosting.$error }"
                  placeholder="12:30"
                />
                <template v-if="validate.jamPosting.$error">
                  <div
                    v-for="(error, index) in validate.jamPosting.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="input-form">
              <label
                for="validation-form-1"
                class="form-label w-full flex flex-col sm:flex-row mt-3"
              >
                Kategori
                <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                  >Harus di isi, Pilih salah satu</span
                >
              </label>
              <select
                id="validation-form-3"
                v-model.trim="validate.kategori.$model"
                name="kategori"
                class="form-select mt-2 sm:mr-2"
                :class="{ 'border-theme-6': validate.kategori.$error }"
                aria-label="Default select example"
              >
                <option
                  v-for="(ct, index) in category"
                  :key="index"
                  :value="ct.idCategory"
                >
                  {{ ct.category }}
                </option>
              </select>
              <template v-if="validate.kategori.$error">
                <div
                  v-for="(error, index) in validate.kategori.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="input-form">
              <label
                for="validation-form-1"
                class="form-label w-full flex flex-col sm:flex-row mt-3"
              >
                Title
                <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                  >Harus di isi, minimal 6 karakter</span
                >
              </label>
              <input
                id="validation-form-4"
                v-model.trim="validate.title.$model"
                type="text"
                name="title"
                class="form-control"
                :class="{ 'border-theme-6': validate.title.$error }"
                placeholder="Title"
              />
              <template v-if="validate.title.$error">
                <div
                  v-for="(error, index) in validate.title.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="input-form mt-3">
              <label
                for="validation-form-5"
                class="form-label w-full flex flex-col sm:flex-row"
              >
                Subtitle
                <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                  >Optional</span
                >
              </label>
              <textarea
                id="validation-form-5"
                v-model.trim="validate.subtitle.$model"
                class="form-control"
                name="comment"
                placeholder="Tulis subtitle disini"
              ></textarea>
            </div>
            <div class="form-label w-full flex flex-col sm:flex-col mt-3">
              <!-- <image-compressor
                id="imgupload"
                accept="image/*"
                style="display: none"
                multiple
                @change="handleFileUpload"
                :done="getFiles"
                :scale="scale"
                :quality="quality"
              >
              </image-compressor> -->
              <input
                type="file"
                id="imgupload"
                accept="image/*"
                multiple
                @change="handleFileUpload"
                style="display: none"
              />
              <button
                class="btn btn-outline-dark w-24"
                type="button"
                @click="openfile()"
              >
                <ImageIcon class="mr-2" /> Images
              </button>
              <div
                v-if="listGambar.length > 0"
                class="w-full intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5"
                style="border: 1px solid grey; border-radius: 10px"
              >
                <div
                  v-for="item in listGambar"
                  :key="item.id"
                  class="
                    intro-y
                    col-span-12
                    md:col-span-4
                    sm:col-span-12
                    lg:col-span-4
                    2xl:col-span-4
                  "
                >
                  <!-- <v-icon
                    :color="'red'"
                    medium
                    class="float-sm-right"
                    v-on:click="removeImage(item)"
                    v-text="'mdi-close-circle-outline'"
                  ></v-icon> -->

                  <div
                    class="
                      file
                      box
                      rounded-md
                      px-5
                      pt-8
                      pb-5
                      px-3
                      sm:px-5
                      relative
                      zoom-in
                    "
                  >
                    <!-- <div class="absolute left-0 top-0 mt-3 ml-3">
                      <input
                        class="form-check-input border border-gray-500"
                        type="checkbox"
                      />
                    </div> -->

                    <a
                      href=""
                      class="w-3/5 file__icon file__icon--image mx-auto"
                    >
                      <div class="file__icon--image__preview image-fit">
                        <img alt="" :src="item.img" />
                      </div>
                    </a>
                    <!-- <a
                      href=""
                      class="block font-medium mt-4 text-center truncate"
                      >{{ faker.files[0].fileName }}</a
                    >
                    <div class="text-gray-600 text-xs text-center mt-0.5">
                      {{ faker.files[0].size }}
                    </div> -->
                    <div
                      class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto"
                    >
                      <a
                        class="dropdown-toggle w-5 h-5 block"
                        href="javascript:;"
                        aria-expanded="false"
                      >
                        <MoreVerticalIcon class="w-5 h-5 text-gray-600" />
                      </a>
                      <div class="dropdown-menu w-40">
                        <div
                          class="dropdown-menu__content box dark:bg-dark-1 p-2"
                        >
                          <a
                            href=""
                            @click="removeImage(item)"
                            class="
                              flex
                              items-center
                              block
                              p-2
                              transition
                              duration-300
                              ease-in-out
                              bg-white
                              dark:bg-dark-1
                              hover:bg-gray-200
                              dark:hover:bg-dark-2
                              rounded-md
                            "
                          >
                            <TrashIcon class="w-4 h-4 mr-2" /> Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-form mt-3">
              <label
                for="validation-form-6"
                class="form-label w-full flex flex-col sm:flex-row"
              >
                Caption
                <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                  >Optional, Deksripsi posting</span
                >
              </label>
              <div>
                <div class="preview">
                  <ClassicEditor
                    id="validation-form-6"
                    placeholder="Caption disini"
                    v-model.trim="validate.caption.$model"
                  />
                </div>
              </div>
            </div>
            <div class="input-form mt-3">
              <label
                for="validation-form-7"
                class="form-label w-full flex flex-col sm:flex-row"
              >
                Video URL
                <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                  >Optional, URL format</span
                >
              </label>
              <input
                id="validation-form-7"
                v-model.trim="validate.videoUrl.$model"
                type="url"
                name="videoUrl"
                class="form-control"
                :class="{ 'border-theme-6': validate.videoUrl.$error }"
                placeholder="https://kalenderkonten.com"
              />
              <template v-if="validate.videoUrl.$error">
                <div
                  v-for="(error, index) in validate.videoUrl.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="input-form mt-3">
              <label
                for="validation-form-8"
                class="form-label w-full flex flex-col sm:flex-row"
              >
                Tag
                <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                  >Optional, Tag Posting</span
                >
              </label>
              <input
                id="validation-form-8"
                v-model="tag"
                type="text"
                name="tag"
                @keyup.enter="addTag()"
                @keyup="checkKey"
                class="form-control"
                placeholder="#photograpy"
              />
              <div class="w-full mt-3" v-if="ListTags.length > 0">
                <div class="flex flex-wrap justify-left space-x-2">
                  <span
                    v-for="(c, index) in ListTags"
                    :key="index"
                    class="
                      px-4
                      py-2
                      rounded-full
                      text-black
                      bg-gray-200
                      font-semibold
                      text-sm
                      flex
                      align-center
                      w-max
                      cursor-pointer
                      active:bg-gray-300
                      transition
                      duration-300
                      ease
                    "
                  >
                    {{ c }}
                    <!-- <button class="bg-transparent hover focus:outline-none">
                    </button> -->
                    <a
                      data-bs-dismiss="modal"
                      class="dropdown-toggle w-5 h-5 block pl-1"
                      href="javascript:;"
                      @click="removeTags(c)"
                      aria-expanded="false"
                    ><XCircleIcon class="w-5 h-5 text-gray-600" /></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="text-right modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 mr-1 btn btn-outline-secondary"
              @click="editKegiatan = false"
            >
              Cancel
            </button>
            <button type="submit" class="w-20 btn btn-primary">
              Save
              <LoadingIcon
                v-if="loading"
                icon="oval"
                color="white"
                class="w-4 h-4 ml-2"
              />
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </form>
    </div>
  </div>
  <!-- modal chat-->
  <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                    <div class="text-3xl mt-5">Anda Yakin ?</div>
                    <div class="text-gray-600 mt-2">
                        anda yakin ingin menghapus {{selectedEvent.title}} ? <br />process ini tidak dapat di batalkan
                    </div>
                </div>
                <div class="px-5 pb-8 text-center">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">
                        batal
                    </button>
                    <button type="button" class="btn btn-danger w-24" @click="eventDelete()">
                        Hapus
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
  <div
    id="chatc-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
    v-if="selectedEvent.id != ''"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div
          class="
            modal-header
            flex flex-shrink-0
            items-center
            justify-between
            border-b border-gray-200
            rounded-t-md
          "
        >
          <h5
            class="text-xl font-medium leading-normal text-black"
            id="exampleModalScrollableLabel"
          >
            Chat {{ selectedEvent.title }}
          </h5>
          <!-- <button
            type="button"
            class="
              btn-close
              box-content
              w-4
              h-4
              p-1
              text-black
              border-none
              rounded-none
              opacity-50
              focus:shadow-none focus:outline-none focus:opacity-100
              hover:text-black hover:opacity-75 hover:no-underline
            "
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
          <a
            data-bs-dismiss="modal"
            class="dropdown-toggle w-5 h-5 block"
            href="javascript:;"
            @click="closeChat()"
            aria-expanded="false"
          >
            <XCircleIcon class="w-5 h-5 text-gray-600" />
          </a>
        </div>
        <div class="modal-body text-center">
          <chats
            :chats="listChats"
            :id="selectedEvent.idKegiatan"
            :title="selectedEvent.title"
          ></chats>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
  id="chatc-modal" tabindex="-1" aria-labelledby="exampleModalScrollableLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
    <div
      class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
          Modal title
        </h5>
        <button type="button"
          class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body relative p-4">
        <p>This is some placeholder content to show the scrolling behavior for modals. We use repeated line breaks to demonstrate how content can exceed minimum inner height, thereby showing inner scrolling. When content becomes longer than the predefined max-height of modal, content will be cropped and scrollable within the modal.</p>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <p>This content should appear at the bottom after you scroll.</p>
      </div>
      <div
        class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
        <button type="button"
          class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
          data-bs-dismiss="modal">
          Close
        </button>
        <button type="button"
          class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div> -->
  <!-- end modal chat-->
  <!-- dialog slide preview -->
  <div
    id="side-preview"
    class="modal modal-slide-over"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div
          class="modal-header p-5 flex space-x-4"
          :style="`background-color: ${selectedEventColor};color:white`"
        >
          <h2 class="font-medium text-base mr-auto">
            Tanggal: {{ selectedEvent.tanggalFormat }}
          </h2>
          <button
            class="btn btn-warning mr-1 mb-2"
            title="Edit"
            @click="eventUpdate()"
          >
            <Edit2Icon class="w-5 h-5" />
          </button>
          <button
            class="btn btn-danger mr-1 mb-2"
            title="hapus"
            @click="deleteDialog()"
          >
            <TrashIcon class="w-5 h-5" />
          </button>
          <!-- <button class="btn btn-primary mr-1 mb-2" title="bagikan">
            <Share2Icon class="w-5 h-5" />
          </button> -->
        </div>
        <div class="modal-body">
          <div v-if="selectedEvent.listGambar.length > 0" class="p-2">
            <TinySlider
              :options="{
                mode: 'gallery',
                speed: 500,
                autoplay: false,
                controls: true,
                items: 1,
                nav: true,
                responsive: {
                  600: {
                    items: 3
                  },
                  480: {
                    items: 2
                  },
                  800: {
                    items: 1
                  }
                }
              }"
            >
              <div
                class="px-2"
                v-for="itm in selectedEvent.listGambar"
                :key="itm.idImage"
              >
                <div
                  class="
                    h-full
                    bg-gray-200
                    dark:bg-dark-1
                    rounded-md rounded-lg
                    p-2
                  "
                >
                  <img alt="" :src="itm.img" />
                </div>
              </div>
            </TinySlider>
          </div>
          <div class="w-full px-1 mt-7">
            <h3
              class="h-full font-medium flex items-center justify-left text-lg"
            >
              Kategori : {{ this.selectedEvent.namaKategori }}
            </h3>
          </div>
          <div class="mt-5" v-if="selectedEvent.title">
            <h5 class="font-regular font-semibold text-md">
              {{ selectedEvent.title }}
            </h5>
          </div>
          <div class="mt-5">
            <h5
              v-html="selectedEvent.deskripsi"
              class="font-regular text-sm"
            ></h5>
          </div>
          <div class="w-full mt-3" v-if="selectedEvent.ListTags.length > 0">
            <h2 class="font-regular flex items-center justify-left text-lg">
              Tags:
            </h2>
            <div class="flex flex-wrap justify-left space-x-1">
              <span
                v-for="(c, index) in selectedEvent.ListTags"
                :key="index"
                class="
                  px-1
                  py-1
                  mt-1
                  rounded-full
                  light:bg-blue
                  dark:bg-white
                  text-black
                  font-semibold
                  text-sm
                "
              >
                {{ c }}
              </span>
            </div>
          </div>
          <div class="w-full mt-4">
            <button
              class="btn btn-sm btn-outline-primary mr-1 mb-2"
              @click="showChat(selectedEvent)"
            >
              <MessageSquareIcon class="w-5 h-5" /> &nbsp; revisi
            </button>
            <button
              @click="approve('Progress')"
              class="btn btn-sm btn-warning mr-1 mb-2"
              v-if="auth.level != 'admin' && selectedEvent.status != 'Draft'"
            >
              <CheckIcon class="w-5 h-5" /> &nbsp; Kirim
              <LoadingIcon
                v-if="loading"
                icon="oval"
                color="white"
                class="w-4 h-4 ml-2"
              />
            </button>
            <button
              @click="approve('Done')"
              class="btn btn-sm btn-success mr-1 mb-2"
              v-if="auth.level == 'admin' && selectedEvent.status != 'Done'"
            >
              <CheckIcon class="w-5 h-5" /> &nbsp; DISETUJUI
              <LoadingIcon
                v-if="loading"
                icon="oval"
                color="white"
                class="w-4 h-4 ml-2"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Script.vue"></script>
