<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      paket: [],
      form: {}
    }
  },
  mounted() {
    this.loadPaket()
  },
  methods: {
    loadPaket() {
      try {
        service.servicePaket().then((response) => {
          // console.log(response)
          switch (response.status) {
            case 200:
              this.paket = response.results
              // console.table(this.paket)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createCategory() {
      try {
        // console.log(this.form)
        service.servicePostPaket(this.form).then((response) => {
          console.log(response)
        })
      } catch (error) {
        console.log(error)
      }
    }

    // updateCategory() {
    //   try {
    //     console.log(this.form)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    // deleteCategory() {
    //   try {
    //     service.serviceDeleteCategory(this.form.idCategory).then((response) => {
    //       console.log(response)
    //     })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  }
}
</script>
