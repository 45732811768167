<template>
  <div>
    <h2 class="mt-10 text-lg font-medium intro-y">Order Layout</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="flex flex-wrap items-center col-span-12 mt-2 intro-y sm:flex-nowrap"
      >
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#added-category-modal"
        >
          <button class="mr-2 shadow-md btn btn-primary">
            Add New Order
          </button></a
        >

        <div class="dropdown">
          <button
            class="px-2 text-gray-700 dropdown-toggle btn box dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="flex items-center justify-center w-5 h-5">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Add Group
              </a>
              <a
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Message
              </a>
            </div>
          </div>
        </div>
        <div class="hidden mx-auto text-gray-600 md:block">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          <div class="relative w-56 text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="w-56 pr-10 form-control box placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="col-span-12 overflow-auto intro-y lg:overflow-visible">
        <table class="table -mt-2 table-report">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Nama Customer</th>
              <th class="whitespace-nowrap">Nama Paket</th>
              <th class="text-center whitespace-nowrap">STATUS</th>
              <th class="text-center whitespace-nowrap">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in orderData"
              :key="index"
              class="intro-x"
            >
              <!-- <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <Tippy
                      tag="img"
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="
                        require(`@/assets/images/${faker.images[0]}`).default
                      "
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                  <div class="w-10 h-10 -ml-5 image-fit zoom-in">
                    <Tippy
                      tag="img"
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="
                        require(`@/assets/images/${faker.images[1]}`).default
                      "
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                  <div class="w-10 h-10 -ml-5 image-fit zoom-in">
                    <Tippy
                      tag="img"
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="
                        require(`@/assets/images/${faker.images[2]}`).default
                      "
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                </div>
              </td> -->
              <td>
                <a href="" class="font-medium whitespace-nowrap">{{
                  order.nama
                }}</a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  {{ order.email }}
                </div>
                <div
                  class="flex mt-3 items-left"
                  v-if="order.status_order == 'Success'"
                >
                  <button class="mr-2 shadow-md btn btn-success" @click="downloadInvoice(order)">
                    <CheckSquareIcon class="w-4 h-4 mr-1" /> Send Invoice
                  </button>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap">{{
                  order.nama_paket
                }}</a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  Rp. {{ new Intl.NumberFormat('de-DE').format(order.harga) }}
                </div>
              </td>
              <td
                v-if="order.status_order == 'Process'"
                class="text-center text-theme-3"
              >
                {{ order.status_order }}
              </td>

              <td
                v-else-if="order.status_order == 'Success'"
                class="text-center text-theme-9"
              >
                {{ order.status_order }}
              </td>

              <td
                v-else-if="order.status_order == 'Pending'"
                class="text-center text-theme-6"
              >
                {{ order.status_order }}
              </td>

              <td class="w-56 table-report__action">
                <div class="flex items-center justify-center">
                  <a
                    class="flex items-center text-theme-12"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#edit-order-modal"
                    v-on:click="detailsOrder = order"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" /> Edit
                  </a>
                  <a
                    class="flex items-center ml-4 text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div
        class="flex flex-wrap items-center col-span-12 intro-y sm:flex-row sm:flex-nowrap"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">1</a>
          </li>
          <li>
            <a class="pagination__link pagination__link--active" href="">2</a>
          </li>
          <li>
            <a class="pagination__link" href="">3</a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select class="w-20 mt-3 form-select box sm:mt-0">
          <option>5</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="edit-order-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <div class="mt-5 text-3xl">Details Order Customer</div>
                <div class="mt-2 text-left text-gray-600">
                  Nama Customer : {{ detailsOrder.nama_customer }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  No WA Customer : {{ detailsOrder.noWA }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Akun IG Customer : {{ detailsOrder.akunIG }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Email Customer : {{ detailsOrder.email }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Status Order : {{ detailsOrder.status_order }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Nama Paket : {{ detailsOrder.nama_paket }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Harga : Rp.
                  {{
                    new Intl.NumberFormat('de-DE').format(detailsOrder.harga)
                  }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Discount : {{ detailsOrder.detailsOrder }} %
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Pajak : {{ detailsOrder.vat }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Lama Berlangganan : {{ detailsOrder.lamaLangganan }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Opsi Pembayaran : {{ detailsOrder.nama_opsibayar }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  No Akun / Rekening : {{ detailsOrder.noAkunRekening }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Nama Kupon : {{ detailsOrder.namaKupon }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Kode Kupon : {{ detailsOrder.kodeKupon }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Kode Kupon : {{ detailsOrder.kodeKupon }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Kode Kupon : {{ detailsOrder.kodeKupon }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="mt-2 text-left text-gray-600">
                  Kode Kupon : {{ detailsOrder.kodeKupon }}
                </div>
                <div class="relative">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-danger"
                  @click="deleteCategory()"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
                <div class="mt-5 text-3xl">Are you sure?</div>
                <div class="mt-2 text-gray-600">
                  Do you really want to delete these records? <br />This process
                  cannot be undone.
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-danger"
                  @click="deleteCategory()"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>
  </div>
</template>
<script src="./Script.vue" />
