<script>
import ApiService from '../../service/api'
const service = new ApiService()
// import { jsPDF } from 'jspdf'
// const pdf = new jsPDF()
// import 'jspdf-autotable'
// import { imgData } from '../../assets/images/index'

export default {
  components: {
    // jsPDF
  },
  data() {
    return {
      orderData: [],
      detailsOrder: ''
    }
  },
  mounted() {
    this.loadOrder()
  },
  methods: {
    loadOrder() {
      try {
        service.serviceGetOrder().then((response) => {
          console.log(response.results)
          switch (response.status) {
            case 200:
              this.orderData = response.results
              console.table(this.orderData)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    downloadInvoice(data) {
      console.log('download invoice ' + data)

      // // // console.log(imgData);
      // // // pdf.text("IMAGE", 80, 10);
      // pdf.addImage(imgData, 'PNG', 80, 10, 50, 20)
      // pdf
      //   .setFont(undefined, 'bold')
      //   .text('PEMBAYARAN BERHASIL', 73, 40)
      //   .setFont(undefined, 'normal')
      // pdf
      //   .setFontSize(11)
      //   .setFont(undefined, 'bold')
      //   .text('Terima kasih atas pembayaran anda', 75, 50, 0, 10)
      //   .setFont(undefined, 'normal')
      // pdf
      //   .setFontSize(11)
      //   .setFont(undefined, 'bold')
      //   .text(
      //     'Kalenderkonten akan selalu menjadi pilihan terbaik anda',
      //     55,
      //     55,
      //     0,
      //     10
      //   )
      //   .setFont(undefined, 'normal')
      // pdf
      //   .setFontSize(22)
      //   .setFont(undefined, 'bold')
      //   .text('No. Invoice', 10, 80, 0, 10)
      //   .setFont(undefined, 'normal')
      // pdf.setFontSize(13).text('#' + this.dataUser.invoice, 10, 90, 0, 10)
      // pdf
      //   .setFontSize(18)
      //   .setFont(undefined, 'bold')
      //   .text('Client details', 10, 110, 0, 10)
      // pdf.text('Payment to', 167, 110, 0, 10).setFont(undefined, 'normal')
      // pdf.setFontSize(13).text(this.dataUser.name, 10, 120, 0, 10)
      // pdf.text('kalenderkonten.com', 160, 120, 0, 10)
      // pdf.text(this.dataUser.emailAdd, 10, 127, 0, 10)
      // pdf
      //   .text('sales@kalenderkonten.com', 144, 127, 0, 10)
      //   .setFont(undefined, 'normal')
      // pdf
      //   .setFont(undefined, 'bold')
      //   .text('@' + this.dataUser.ig, 10, 135, 0, 10)
      // pdf.autoTable({
      //   margin: { top: 145 },
      //   headStyles: {
      //     fillColor: [0, 0, 0, 0.3],
      //     fontStyle: 'bold',
      //     fontSize: 13
      //   },
      //   head: [['Deskripsi', 'Harga', 'Potongan', 'Vat']],
      //   body: [
      //     [
      //       `Paket ${this.dataUser.paketName} ${this.dataUser.lama}  Bulan`,
      //       `Rp. ${new Intl.NumberFormat('de-DE').format(
      //         this.dataUser.harga
      //       )},-`,
      //       this.dataUser.potongan,
      //       `Rp. ${new Intl.NumberFormat('de-DE').format(
      //         this.dataUser.hargaVat
      //       )},-`
      //     ]
      //     //, ["Castille", "castille@example.com", "Spain"],
      //   ]
      // })
      // pdf.text('Total Pembayaran', 162, 200, 0, 10)
      // pdf
      //   .setFontSize(20)
      //   .text(
      //     'Rp ' +
      //       new Intl.NumberFormat('de-DE').format(this.dataUser.totalHarga) +
      //       ',-',
      //     154,
      //     210,
      //     0,
      //     10
      //   )
      //   .setFont(undefined, 'normal')
      // pdf.setFontSize(11).text('Sudah termasuk pajak', 162, 220, 0, 10)
      // pdf.setFontSize(20).text('Pembayaran Lunas', 140, 230, 0, 10)
      // pdf.save('invoice.pdf')
    },

    createCategory() {},

    updateCategory() {},

    deleteCategory() {}
  }
}
</script>
