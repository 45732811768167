<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      teamsData: [],
      usersData: [],
      dialogCreate: false
    }
  },
  mounted() {
    this.loadTeams()
    this.loadUsers()
  },
  methods: {
    loadTeams() {
      try {
        service.serviceGetTeam().then((response) => {
          switch (response.status) {
            case 200:
              this.teamsData = response.data
              // console.table(this.teamsData)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
          console.table(response)
        })
      } catch (error) {
        console.log(error)
      }
    },

    loadUsers() {
      try {
        service.serviceGetUsers().then((response) => {
          switch (response.status) {
            case 200:
              this.usersData = response.results
              console.log(this.usersData)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createTeam() {
      try {
        console.log('create data')
      } catch (error) {
        console.log(error)
      }
    },

    updateTeam() {},

    deleteTeam() {}
  }
}
</script>
