<template>
  <div>
    <!-- BEGIN: Top Users Layout -->
    <h2 class="mt-10 text-lg font-medium intro-y">Users Layout</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="flex flex-wrap items-center col-span-12 mt-2 intro-y sm:flex-nowrap"
      >
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#added-user-modal"
        >
          <button class="mr-2 shadow-md btn btn-primary">
            Add New User
          </button></a
        >

        <div class="dropdown">
          <button
            class="px-2 text-gray-700 dropdown-toggle btn box dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="flex items-center justify-center w-5 h-5">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#added-user-modal"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Add User
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Message
              </a>
            </div>
          </div>
        </div>
        <div class="hidden mx-auto text-gray-600 md:block">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          <div class="relative w-56 text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="w-56 pr-10 form-control box placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
            />
          </div>
        </div>
      </div>
      <!-- END: Top Users Layout -->
      <!-- BEGIN: Users Layout -->

      <div
        v-for="(user, index) in usersData"
        :key="index"
        class="col-span-12 intro-y md:col-span-6"
      >
        <div class="box">
          <div
            class="flex flex-col items-center p-5 border-b border-gray-200 lg:flex-row dark:border-dark-5"
          >
            <!-- <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="require(`@/assets/images/${faker.photos[0]}`).default"
              />
            </div> -->
            <div
              class="mt-3 text-center lg:ml-2 lg:mr-auto lg:text-left lg:mt-0"
            >
              <div class="font-bold">{{ user.nama }}</div>
            </div>
            <div class="flex mt-3 -ml-2 lg:ml-0 lg:justify-end lg:mt-0">
              <Tippy
                tag="a"
                href=""
                class="flex items-center justify-center w-8 h-8 ml-2 text-gray-500 border rounded-full dark:border-dark-5 zoom-in"
                content="Facebook"
              >
                <FacebookIcon class="w-3 h-3 fill-current" />
              </Tippy>
              <Tippy
                tag="a"
                href=""
                class="flex items-center justify-center w-8 h-8 ml-2 text-gray-500 border rounded-full dark:border-dark-5 zoom-in"
                content="Twitter"
              >
                <TwitterIcon class="w-3 h-3 fill-current" />
              </Tippy>
              <Tippy
                tag="a"
                href=""
                class="flex items-center justify-center w-8 h-8 ml-2 mr-6 text-gray-500 border rounded-full dark:border-dark-5 zoom-in"
                content="Linked In"
              >
                <LinkedinIcon class="w-3 h-3 fill-current" />
              </Tippy>
              <div class="absolute top-0 right-0 mt-3 mr-5 dropdown">
                <a
                  class="block w-5 h-5 dropdown-toggle"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreVerticalIcon
                    class="w-5 h-12 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="w-40 dropdown-menu">
                  <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                    <a
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#update-category-modal"
                    >
                      <Edit2Icon class="w-4 h-4 mr-2" /> Edit
                    </a>
                    <a
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-confirmation-modal"
                      v-on:click="form = user"
                    >
                      <TrashIcon class="w-4 h-4 mr-2" />
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ml-6">
            <div class="text-gray-600 text-xs mt-0.5">
              Alamat : {{ user.alamat }}
            </div>
            <div class="text-gray-600 text-xs mt-0.5">
              Jenis Kelamin : {{ user.gender }}
            </div>
            <div class="text-gray-600 text-xs mt-0.5">
              Tanggal Lahir : {{ user.tanggalLahir }}
            </div>
          </div>

          <div
            class="flex flex-wrap items-center justify-center p-5 lg:flex-nowrap"
          >
            <div class="w-full mb-4 mr-auto lg:w-1/2 lg:mb-0"></div>
            <button class="px-2 py-1 mr-2 btn btn-primary">Message</button>
            <button class="px-2 py-1 btn btn-outline-secondary">Profile</button>
          </div>
        </div>
      </div>
      <!-- END: Users Layout -->
      <!-- BEGIN: Add user Modal -->
      <div id="added-user-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-left">
                <div class="text-3xl">Add User</div>
                <div class="mt-6">
                  <label for="crud-form-1" class="form-label">Name</label>
                  <input
                    id="crud-form-1"
                    type="text"
                    class="w-full form-control"
                    placeholder="Input name"
                    v-model="form.name"
                  />
                  <label for="crud-form-1" class="mt-2 form-label"
                    >Username</label
                  >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="w-full form-control"
                    placeholder="Input Username"
                    v-model="form.username"
                  />
                  <label for="crud-form-1" class="mt-2 form-label"
                    >Tanggal Lahir</label
                  >
                  <div class="relative">
                    <div
                      class="absolute flex items-center justify-center w-10 h-full text-gray-600 bg-gray-100 border rounded-l dark:bg-dark-1 dark:border-dark-4"
                    >
                      <CalendarIcon class="w-4 h-4" />
                    </div>
                    <Litepicker
                      v-model="form.date"
                      :options="{
                        autoApply: false,
                        showWeekNumbers: true,
                        dropdowns: {
                          minYear: 1990,
                          maxYear: null,
                          months: true,
                          years: true
                        }
                      }"
                      class="pl-12 form-control"
                    />
                  </div>
                  <label for="crud-form-1" class="mt-2 form-label"
                    >Password</label
                  >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="w-full form-control"
                    placeholder="Input Password"
                    v-model="form.password"
                  />
                  <label for="crud-form-1" class="mt-2 form-label"
                    >Repeat Password</label
                  >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="w-full form-control"
                    placeholder="Input Repeat Password"
                    v-model="form.repeatPassword"
                  />

                  <label for="crud-form-1" class="mt-2 form-label"
                    >Gender</label
                  >
                  <div class="flex ml-3">
                    <div>
                      <div class="form-check">
                        <input
                          class="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="Pria"
                          v-model="form.geder"
                        />
                        <label
                          class="inline-block text-gray-800 form-check-label"
                          for="flexRadioDefault1"
                        >
                          Pria
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="Wanita"
                          v-model="form.gender"
                        />
                        <label
                          class="inline-block text-gray-800 form-check-label"
                          for="flexRadioDefault2"
                        >
                          Wanita
                        </label>
                      </div>
                    </div>
                  </div>
                  <label for="crud-form-1" class="mt-3 form-label">Alamat</label
                  ><textarea
                    class="
                      form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700
                      focus:bg-white
                      focus:border-blue-600
                      focus:outline-none
                    "
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Input alamat"
                    v-model="form.alamat"
                  ></textarea>
                </div>
              </div>
              <div class="px-5 pb-8 text-right">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-success"
                  @click="createdUser()"
                >
                  Created
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Add user Modal -->
      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
                <div class="mt-5 text-3xl">Are you sure?</div>
                <div class="mt-2 text-gray-600">
                  Do you really want to delete these records? <br />This process
                  cannot be undone.
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-danger"
                  @click="deleteUser()"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
      <!-- BEGIN: Pagination -->
      <div
        class="flex flex-wrap items-center col-span-12 intro-y sm:flex-row sm:flex-nowrap"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">1</a>
          </li>
          <li>
            <a class="pagination__link pagination__link--active" href="">2</a>
          </li>
          <li>
            <a class="pagination__link" href="">3</a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select class="w-20 mt-3 form-select box sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<script src='./Script.vue' />
